import countries from 'i18n-iso-countries'
import { countries as countriesList } from 'countries-list'

// This mixin provides lazy-loaded country translations, reacting in realtime to language changes
// Usage:
// await this.setCountriesLocale(this.$store.state.locale) // If you need to ensure this.countries is populated before retrieving a country name:
// this.countries['countryCode']
export default {
  data() {
    return {
      countries: {},
    }
  },
  computed: {
    countryItems() {
      return Object.entries(this.countries).map(country => ({ text: country[1], value: country[0] }))
        .sort((a, b) => a.text.localeCompare(b.text))
    },
  },
  async created() {
    this.setCountriesLocale(this.$store.state.locale)
  },
  watch: {
    '$store.state.locale': function (newLocale) { this.setCountriesLocale(newLocale) },
  },
  methods: {
    async setCountriesLocale(locale) {
      countries.registerLocale(await import(`i18n-iso-countries/langs/${locale}`))
      this.countries = countries.getNames(locale)
    },
    getTextCountry(value) {
      return this.countryItems.find(c => c.value === value)?.text
    },
  },
}
